.body-page-transition{
  overflow: hidden;
  #page-transition {
    position: fixed;
    z-index: 9999;
    width: 100%;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  #page-transition > div{
    &:after{
      content: "";
      background-size: cover;
      position: fixed;
      z-index: 9998;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    &:before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      z-index: 9999;
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .page-transition-wrapper-div {
    margin: 100px auto;
    padding: 0px;
    max-width: 360px;
    text-align: center;
    position: relative;
    z-index: 9999;
    top: 0;
  }
  .page-transition-icon-wrapper {
    display: block;
  }
}
